exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-color-shades-generator-js": () => import("./../../../src/pages/color-shades-generator.js" /* webpackChunkName: "component---src-pages-color-shades-generator-js" */),
  "component---src-pages-css-border-generator-js": () => import("./../../../src/pages/css-border-generator.js" /* webpackChunkName: "component---src-pages-css-border-generator-js" */),
  "component---src-pages-css-box-shadow-generator-js": () => import("./../../../src/pages/css-box-shadow-generator.js" /* webpackChunkName: "component---src-pages-css-box-shadow-generator-js" */),
  "component---src-pages-css-button-generator-js": () => import("./../../../src/pages/css-button-generator.js" /* webpackChunkName: "component---src-pages-css-button-generator-js" */),
  "component---src-pages-css-buttons-js": () => import("./../../../src/pages/css-buttons.js" /* webpackChunkName: "component---src-pages-css-buttons-js" */),
  "component---src-pages-css-glassmorphism-generator-js": () => import("./../../../src/pages/css-glassmorphism-generator.js" /* webpackChunkName: "component---src-pages-css-glassmorphism-generator-js" */),
  "component---src-pages-css-gradient-generator-js": () => import("./../../../src/pages/css-gradient-generator.js" /* webpackChunkName: "component---src-pages-css-gradient-generator-js" */),
  "component---src-pages-css-hex-color-picker-js": () => import("./../../../src/pages/css-hex-color-picker.js" /* webpackChunkName: "component---src-pages-css-hex-color-picker-js" */),
  "component---src-pages-css-image-filter-generator-js": () => import("./../../../src/pages/css-image-filter-generator.js" /* webpackChunkName: "component---src-pages-css-image-filter-generator-js" */),
  "component---src-pages-css-text-shadow-generator-js": () => import("./../../../src/pages/css-text-shadow-generator.js" /* webpackChunkName: "component---src-pages-css-text-shadow-generator-js" */),
  "component---src-pages-css-transform-generator-js": () => import("./../../../src/pages/css-transform-generator.js" /* webpackChunkName: "component---src-pages-css-transform-generator-js" */),
  "component---src-pages-css-underline-generator-js": () => import("./../../../src/pages/css-underline-generator.js" /* webpackChunkName: "component---src-pages-css-underline-generator-js" */),
  "component---src-pages-darken-color-js": () => import("./../../../src/pages/darken-color.js" /* webpackChunkName: "component---src-pages-darken-color-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lighten-color-js": () => import("./../../../src/pages/lighten-color.js" /* webpackChunkName: "component---src-pages-lighten-color-js" */)
}

